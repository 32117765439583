@banner1: component-5d3fs35s2s;  
 .@{banner1} {
    .viewer-wrapper {
        position: relative;
        background: #333;
        height: 100%;
    }
    .viewer-backdrop{
        background-color: #ffffff;
    }
    .ant-upload .ant-upload-btn{
        padding: 0;
    }
    .ant-upload.ant-upload-drag{
        border: 1px #d9d9d9;
    }
    .viewer {
        height: 100%;
        .images {
        height: 100%;
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        flex-wrap: wrap;
        padding: 5px;
        .image-wrapper {
            display: inline-block;
            width: calc(33% - 20px);
            margin: 5px 5px 0 5px;
            .image {
            width: 100%;
            cursor: pointer;
            display: inline-block;
            }
        }
        }
    }
    .ant-card-body{
        .viewer-toolbar{
            margin-left: 5px;
            ul{
                float: left;
                margin: 5px 0;
                // li{
                //     float: initial;
                // }
            }
        }
    }
       
    
    // .ant-card-body{
    //     height: 681px;
    // }

    .active{
        background-color: #547da6;
        
    }
    .active .ant-typography{
        color: #FFF!important;
        // background-color: #ffffff;
        font-weight: 600;
    }
    .active_error{
        // color: #b2363d;
        color: red;
    }
    .ant-divider-vertical{
        height: 1.7em;
    }

    .guHanZi{
        font-family: "UniHY_Kai_CJK+", "HT_CJK+", "楷体", "宋体", "unihan_eudc";
        font-size: 18px;
        // line-height: 18px;
    }

  } 
  .choice .ant-popover-inner-content{
    padding: 0;
  }
  .dasd222{
      .ant-table-tbody .ant-table-row{
          height: 55px!important;
          box-sizing: border-box;
      }
      canvas{
          width: 30%;
      }
  }
  .sad2232{
      .box > div{
          border-bottom: 1px solid #ccc;
          padding: 10px 0;
      }
      .box > div p{
        margin-bottom: 0;
        width: 130px;
        text-align: right;
        margin-right: 10px;
      }
      .box > div > span{
          background-color: #ccc;
          padding: 0 15px;
          font-size: 15px;
      }
      .jiantou span{
          background-color: #ccc;
          margin: 0 5px;
      }
      .box div .jia{
          background-color: #fff;
      }
      .ant-popover-content{
          width: 350px;
      }
      .ant-popover-title{
          padding: 15px 10px;
      }
      .ant-popover-inner-content{
          padding: 0 12px;
          padding-bottom: 200px;
      }
      .xia{
          display: flex;
          align-items: flex-end;
          flex-direction: column-reverse;
      }
     
  }
.tongji{
    background-color: #Fff;
    border: 1px solid #ccc;
    box-shadow: 5px;
    position: absolute;
    bottom: 5%;
    right: 30px;
    width: 353px;
    padding-bottom: 50px;
}
.tjtitle{
    padding: 10px;
    border-bottom: 1px solid #ccc;
    display: flex;
    align-content: center;
}
.tjbody{
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    padding-right: 5px;
}
.lan{
    color: #3458cede;
    font-size: 15px;
    padding: 0 2px;
}
.hg{
    color: #b2363d;
}
.tjbody div{
    padding: 5px 10px;
    border: 1px dashed #3458cede;
    color: #3458cede;
    font-size: 15px;
    margin: 5px;
}
.ant-select-selector{
    background: rgba(114, 164, 190,1) !important;
  }
@primary-color: #1890ff;@link-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 4px;@border-color-base: #d9d9d9;@box-shadow-base: 0 2px 8px rgba(0, 0, 0, 0.15);
.choice .ant-popover-inner-content{
padding: 0;
} 
.dasd222  .ant-table-tbody .ant-table-row{
height: 55px!important;
box-sizing: border-box;
}
.dasd222 canvas{
width: 30%;
}
.sad2232 .box > div{
border-bottom: 1px solid #ccc;
padding: 10px 0;
}
.sad2232 .box > div p{
margin-bottom: 0;
width: 130px;
text-align: right;
margin-right: 10px;
}
.sad2232 .box > div > span{
background-color: #ccc;
padding: 0 15px;
font-size: 15px;
}
.sad2232 .jiantou span{
background-color: #ccc;
margin: 0 5px;
}
.sad2232 .box div .jia{
background-color: #fff;
}
.sad2232 .ant-popover-content{
width: 350px;
}
.sad2232 .ant-popover-title{
padding: 15px 10px;
}
.sad2232 .ant-popover-inner-content{
padding: 0 12px;
padding-bottom: 200px;
}
.sad2232 .xia{
display: flex;
align-items: flex-end;
flex-direction: column-reverse;
}
.tongji{
background-color: #Fff;
border: 1px solid #ccc;
box-shadow: 5px;
position: absolute;
bottom: 5%;
right: 30px;
width: 353px;
padding-bottom: 50px;
}
.tjtitle{
padding: 10px;
border-bottom: 1px solid #ccc;
display: flex;
align-content: center;
}
.tjbody{
display: flex;
flex-wrap: wrap;
padding: 10px;
padding-right: 5px;
}
.lan{
color: #3458cede;
font-size: 15px;
padding: 0 2px;
}
.hg{
color: #b2363d;
}
.tjbody div{
padding: 5px 10px;
border: 1px dashed #3458cede;
color: #3458cede;
font-size: 15px;
margin: 5px;
}

.ant-card-extra .ant-radio-button-wrapper{
  padding: 0 5px;
}
.ant-card-head-wrapper .ant-card-extra .ant-radio-group .ant-radio-button-wrapper {
  background-color:rgb(222, 225, 228);
  border: 0;
  height: 46px;
  width: 47px;
}
.tubiao svg{
  padding: 14px 0 0 6px;
}
.ant-card-head-wrapper .ant-card-extra .ant-radio-group .ant-radio-button-wrapper-checked{
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  background-color: #fff;
  border-top: 0;
  border-bottom: 0;
  border-radius: 0;
  box-shadow: none;
  outline: none;
}
.ant-card-head-wrapper .ant-card-extra .ant-radio-group .ant-radio-button-wrapper-checked .ant-radio-button-checked{
  border: 0;
}
.ant-row .ant-card .ant-card-head .ant-card-head-wrapper{
  display: flex;
  height: 100%;
  justify-content: space-between;
  align-items: center;
}
.ant-row .ant-card .ant-card-head .ant-card-head-wrapper .ant-card-extra .ant-row{
  display: flex;
  align-items: center;
}
#keyboart_ .ant-card-head-wrapper{
  display: flex;
  height: 100%;
  justify-content: space-between;
  align-items: center;
}
h3.ant-typography{
  margin-bottom: 0;
  line-height: 60px;
}
.iuily .ant-radio-button-wrapper{
  border-radius: 4px;
  width: 90px;
  height: 30px;
  padding: 0;
  line-height: 30px;
  text-align: center;
  font-size: 12px;
  color: #777777;
  box-sizing: border-box;
  background: #FFFFFF;  
}
.iuily .ant-radio-button-wrapper{
  border-top-width: 1px; 
  border-left-width: 1px; 
}
.iuily .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
  color: #487FAB; 
  border: 0;
}
.iuily .ant-radio-button-wrapper > .ant-radio-button{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.iuily .ant-radio-button-checked {
  border: 1px solid #487FAB;
  color: #487FAB;
  border-radius: 4px;
}
.ant-select-selector{
  background: rgba(114, 164, 190,1);
}
.Center123{
  margin-top: 0;
  background-color: #FFFFFF;
  /* height: 956px; */
  /* padding: 0 66px; */
}
.Center123 .top .ant-tabs-nav-wrap{
  height: 62px;
}
.Center123 .top  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
  font-size: 20px;
  color: #454545;
  font-weight: bold;
}
.Center123 .top .ant-tabs-tab-btn{
  font-size: 20px;
  color: #777777;
}
.Center123 .top .ant-tabs-tab{
  margin-right: 51px;
}
.jiben{
  margin-top: 40px;
}
.Center123 .top .ant-tabs-top > .ant-tabs-nav{
  margin-bottom: 0;
  padding: 0 66px;
}
.Center123 .top .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar{
  background: #3AA4CE;
}
.Center123 .jiben .ant-form-item {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}
.Center123 .jiben .ant-form-item{
  display: flex;
  justify-content: center;
}
.Center123 .jiben .ant-form-item .ant-form-item-control{
  width: 400px;
  flex-grow: unset;
}
.jiben .tes{
  margin-top: 15px;
}
.jiben .ant-form-item .ant-form-item-label{
  width: 70px;
  text-align: right;
}
.Center123 .jiben p{
  margin-bottom: 0;
  width: 70px;
  text-align: right;
}
.Center123 .jiben .ant-upload.ant-upload-select-picture-card{
  background: #FFFFFF;
  border: 1px solid #3AA4CE;
  border-radius: 4px;
}
.Center123 .jiben .ant-upload-picture-card-wrapper,
.Center123 .jiben .ant-upload{
  padding: 0;
  margin: 0;
  background: #FFFFFF;
  border-radius: 4px;
  width: 90px;
  height: 30px;
  font-size: 12px;
  color: #3AA4CE;
}
.Center123 .jiben .ant-input{
  width: 400px;
  height: 34px;
  font-size: 14px;
  color: #454545;
}
.Center123 .jiben .qiguai{
  width: 400px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.Center123 .jiben .qiguai .img{
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: aqua;
}
.Center123 .jiben .qiguai .xiaozi{
  font-size: 12px;
  color: #999999;
}
.Center123 .jiben .ant-radio-group{
  width: 400px;
  display: flex;
  justify-content: flex-start;
}
.Center123 .jiben .ant-btn{
  padding: 0;
  width: 140px;
  height: 40px;
  margin: 0 10px;
  text-align: center;
  line-height: 40px;
  border-radius: 4px;
  font-size: 14px;
  margin-top: 40px;
  margin-bottom: 341px;
}
.ant-radio-checked .ant-radio-inner{
  border: 1px solid #487FAB;
}
.ant-radio-inner::after{
  background-color:#487FAB;
}
.Center123 .jiben .cz{
  background: #FFFFFF;
  border: 1px solid #D7D9DC;
  color: #454545;
}
.Center123 .jiben .tj{
  background: #3AA4CE;
  color: #FFFFFF;
}

.news{
  height: 891px;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  margin-top: 40px;
  padding: 0 20px;
}
.news .items {
  width: 912px;
  height: 63px;
  background: #FFFFFF;
  border: 1px solid #E9EDF3;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.06);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px 0 9px;
  margin: 0 9px;
  cursor: pointer;
  margin-bottom: 12px;
}
.news .items > div{
  display: flex;
  align-items: center;
}
.news .items > div > div{
  width: 44px;
  height: 44px;
  font-size: 16px;
  color: #FFFFFF;
  text-align: center;
  line-height:44px;
  margin-right: 10px;
}
.news .items > div > p{
  font-size: 14px;
  color: #454545;
  margin-bottom: 0; 
}
.news .items > span{
  font-size: 14px;
  color: #999999;
}
.Center123 .ant-tabs-content-holder #rc-tabs-0-panel-3{
  padding: 0 21px;
}
.Center123 .ant-tabs-content-holder #rc-tabs-0-panel-2{
  padding: 0 21px;
}
.xiangq .ant-popover-inner{
  width: 843px;
  height: 300px;
}
.xiangq .tetitle{
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.xiangq .tetitle > p{
  font-size: 18px;
  color: #454545;
  margin-bottom: 0;
  width: 649px;
}
.xiangq .tetitle > span{
  font-size: 14px;
  color: #999999;
}
.xiangq .tecontent{
  font-size: 14px;
  color: #454545;
}
.ss123 .ant-btn:nth-child(3){
  border-right: 1px solid #D8D8D8;
  margin-right: 16px;
}
.sbwen{
  padding: 30px;
}
.Ctops{
  padding: 0;
  justify-content: flex-end;
  margin-bottom: 20px;
}
.right{
  /* min-height: 500px; */
  border: 1px solid #E8E8E8;
  font-size: 18px;
  color: #454545;
  line-height: 32px;
  text-align: left;
  padding: 20px;
}

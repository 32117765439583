@import '~antd/dist/antd.css';
.App {
  text-align: center;
}
.stop-pointer-events {
  /* 禁用 */
  /* pointer-events: none; */
}
/* 遮罩层与提示 */
.App .mask {
    width: 100%;
    height: 100%;
    background-color: #e0dede;
    position: absolute;
    z-index: 99999999999;
    opacity: 0.5;
}
.App .tipinfo {
  font-family: "Microsoft YaHei"!important;
  text-align: start;
  z-index: 99999999999;
  width: 45%;
  /* height: 40%; */
  background-color: #fff;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%,-50%);
  padding: 30px 20px 30px;
  padding-left: 80px;
}
.App .tipinfo .fir {
  font-size: 16px;
  font-weight: 550;
}
.App .tipinfo .yx {
  font-size: 13px;
  color: #575757;
}
.App .tipinfo .yx a{
  cursor: auto;
  color: #0f76e7;
}
.App .tipinfo .ewm {
  border: 1px solid #bbb;
  width: 122px;
  height: 122px;
}
.App .tipinfo .ewm img {
  display: block;
  width: 100%;
  height: 100%;
}
/* .App .tip {
    width: 100%;
    height: 60%;
    position: absolute;
    top: 40%;
    overflow: hidden;
    z-index: 99999999999;
}
.App .tip h1{
    font-size: 40px !important;
} */
/* 遮罩层与提示结束 */
.bg-93ds95{
  background-color: #f1f4f7;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  /* color: #61dafb; */
  color: #1890ff;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

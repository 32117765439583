.message{
    width: 80%;
    padding-top: 40px;
    margin: auto;
    font-family: "中粗";
}
.mestitle{
    height: 80px;
    width: 360px;
    margin-bottom:20px
}
.mesLeft{
    float:left;
    padding-top: 15px;
}
.mesRig{
    /* float: right; */
    line-height: 80px;
    font-size: 25px;
    padding-left: 18px;
    width: 80%;
    display: flex;
}
.mesContent{
    margin-top:15px;
    padding: 0 25px;
}
.selectRig{
    float: right;
    margin-left: 20px;
}
.ant-list-split .ant-list-item {
    border-bottom: 1px solid #cbcbcb;
}
.ant-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 0;
    color: rgb(0 0 0 / 85%);
    font-size: 20px;
}
.text{
    font-size: 18px;
    font-family: "中粗";
}
.mesContent button {
    background: none;
    border: none;
    font-size: 16px;
    font-family: "中粗";
    color: #1890ff;
}
.ant-menu-vertical > .ant-menu-item {
    height: 30px;
    line-height: 30px;
}
.avatar {
    margin-top: 30px;
    border-block-end: 1px solid;
    padding-block-end: 35px;
}
.avatar a {
    color: #1890ff;
    font-size: 18px;
}
.fileTab .ant-table-cell{
    font-size: 16px;
    font-family: "中粗"; 
    text-align: center;
}
.ant-table.ant-table-small thead>tr>th {
    background: #e7ecef;
}
.avatar .ant-col .ant-col-14 .col14{
    padding-left: 10%!important;
}
.fileTab tr{
    border-block-end: 1px solid #1a1717;
}
.action .popicon svg{
    width: 1.5em;
    height: 1.5em;
}
.ant-table-column-sorter-full {
    margin-top: -0.2em;
    margin-bottom: 0;
    padding-left: 10px;
    padding-bottom: 5px;
}
.action .popicon{
    margin-right: 30px;
}
.ant-select-selector {
    background: none !important;
}
.selectRig div{
    width: 120px!important;
}
a{
  color:#1890ff;
}
.direction .ant-select-arrow {
  top: 30px;
  color: #333;
}
.direction .ant-select-selector{
  margin-top: 10px;
  margin-left: 15px;
  color: #333;
  padding: 10px;
}
.direction .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 40px;
  padding: 5px 11px;
}
.direction .ant-select-single.ant-select-show-arrow .ant-select-selection-item{
  padding-right: 80px;
  text-align: left;
}
.direction .ant-select-show-arrow{
  margin-right: 35px;
}
.tasklist .ant-upload.ant-upload-drag{
  height: 40px;
  margin-top: 10px;
  line-height: 40px;
  width: 500px;
  text-align: left;
  border: none;
}
.tasklist .ant-upload.ant-upload-drag p.ant-upload-text {
  margin: 0;
  font-size: 14px;
  color: #333;
  padding-left: 10px;
}
.tasklist .ant-upload-drag{
  margin: 8px 0;
  padding: 0;
}
.tasklist .ant-upload.ant-upload-drag .ant-upload {
  padding: 0;
}
.tasklist .ant-upload-drag-container{
  padding: 0;
}
.tasklist .ant-row .ant-btn-text{
  height: 38px;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  background: #3AA4CE;
  border-radius: 4px;
  font-family: PingFangSC-Semibold;
  font-size: 14px;
  color: #FFFFFF;
}
.tasklist .desctext{
  line-height: 60px;
  font-size: 12px;
  color: #ff6700;
  margin-left: 10px;
}
.tasklist .site-layout-background .tops{
  display: flex;
  justify-content: flex-end;
  position: relative;
  margin-bottom: 30px;
}
.tasklist .site-layout-background .ant-row .ant-col h1{
  font-size: 20px;
  line-height: 40px;
  font-weight: bold;
  margin-right: 20px;
}
.tasklist .site-layout-background .ant-row .titled{
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
}

.tasklist .ant-row .ant-btn-all,
.tasklist .ant-row .ant-btn-ending,
.tasklist .ant-row .ant-btn-Ongoing{
  width: 120px;
  text-align: center;
  padding: 8px 0;
  height: 40px;
}
.tasklist .ant-row .ant-btn-Ongoing{
  margin-right: 20px;
}
.tasklist .ant-picker-range .ant-picker-active-bar{
  margin-left: 30px;
}
.tasklist .ant-row .ant-picker-range{
  margin-right: 10px;
}
.tasklist .conents{
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.tasklist .ant-progress-line {
  width: 250px;
}
.tasklist .imgs{
  width: 85px;
  height: 85px;
  background-color: #ccc;
}
.tasklist .progress{
  display: inline-flex;
}
.tasklist .ant-progress-outer {
  width: 86%;
}
.tasklist .yangli{
  background-color: #3AA4CE;
  padding: 5px 20px;
  border: 1px solid #3AA4CE;
  border-radius: 3px;
}
.tasklist .yangli:hover{
  background-color: #3AA4CE;
}
.tasklist .desc div,.time div{
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 10px;
  color: #000;
}
.tasklist .desc span,.time span{
  color: #ccc;
}
.tasklist .ant-table-cell .imgs{
  margin-right: 0;
}
.tasklist .ant-table-tbody .ant-table-cell:first-child{
  width: 115px;
  min-height: 800px;
}
.ant-table-tbody > tr > td{
  /* border: 0; */
}
.tasklist .choose span:nth-child(1){
  color: #4e81ae;
}
.tasklist .choose span:nth-child(2){
  color: #be8e44  ;
}
.tasklist .progress_icon span svg{
  margin: 0px 3px -4px;
  cursor: pointer;
  font-size: 18px;
}
.tasklist .choose span{
  padding: 0 10px;
  cursor: pointer;
}
.tasklist .choose .delate-item{
  padding: 0 10px;
  cursor: pointer;
  color: #be8e44;
  font-size: 14px;
}
.tasklist .choose span:nth-child(1){
  /* border-right: 1px solid #be8e44; */
}
.tasklist .choose{
  text-align: right;
}
.tasklist .choose div,
.tasklist .choose .ant-btn-primary{
  padding: 0;
  display: inline-block;
  color: #3458cede;
  border: 0;
  background-color: #fff;
  box-shadow: none;
}
.sad333 .ant-modal-body{
  height: 380px;
}
.tasklist .ant-radio-button-wrapper{
  background: #FFFFFF;
  border: 1px solid #D8D8D8;
  border-radius: 4px;
  width: 120px;
  height: 38px;
  font-family: PingFangSC-Regular;
  font-size: 14px;
  /* color: #777777; */
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}
.tasklist .ant-radio-button-wrapper:hover{
  border: 1px solid #487FAB;
  color: #487FAB;
}
.tasklist .ant-radio-group{
  display: flex;
}
.ant-radio-button-wrapper:not(:first-child)::before{
  display: none;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before{
  border: 0;
  background-color:transparent;
  display: none;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
  color: #487FAB;
}
.ant-radio-button-checked {
  border: 1px solid #487FAB;
  color: #487FAB;
  border-radius: 4px;
}
.ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child{
  border: 0;
}
.asadzz999 .ant-modal-body{
  padding: 24px;
}
.asadzz999 .ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon{
  color: #3AA4CE;
}
.asadzz999 .ant-modal-footer{
  display: flex;
  justify-content: flex-end;
}
.asadzz999 .ant-modal-footer .ant-btn{
  height: 36px;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  background: #3AA4CE;
  border-radius: 4px;
  font-family: PingFangSC-Semibold;
  font-size: 14px;
  color: #FFFFFF;
  border: 0;
}
.asadzz999 .ant-modal-footer .ant-btn:nth-child(1){
  color: #454545;
  background: #FFFFFF;
  border: 1px solid #D8D8D8;
  border-radius: 3px;
}
.asadzz999 .ant-modal-footer.ant-btn:hover,
.asadzz999 .ant-modal-footer .ant-btn:focus{
  border: 0;
}
.Ctop{
  padding: 30px;
  display: flex;
  justify-content: space-between;
}
.Ctop > span{
  font-family: PingFangSC-Semibold;
  font-size: 22px;
  color: #454545;
}
.Ctop .Right{
  display: flex;
  align-items: center;
}
.Ctop .Right > div{
  display: flex;
  align-items: center;
}
.Ctop .Right > div .ant-picker{
  width: 200px;
  height: 38px;
  background: #FFFFFF;
  border: 1px solid #D7D9DC;
  border-radius: 4px;
  margin-right: 18px;
  margin-left: 10px;
}
.Right .ant-input-affix-wrapper-lg{
  width: 240px;
  height: 38px;
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #999999;
} 
.Right .ant-btn{
  width: 120px;
  height: 38px;
  padding: 0;
  background: #FFFFFF;
  border: 1px solid #D8D8D8;
  border-right:0;
  border-radius: 4px;
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #777777;
  text-align: center;
} 
.Right .ant-btn:nth-child(5){
  border-right: 1px solid #D8D8D8;
  margin-right: 16px;
}
.Right .ant-btn:focus{
  background: #FFFFFF;
  border: 1px solid #3AA4CE;
  border-radius: 4px;
  color: #3AA4CE;
}
.Right .ant-input-affix-wrapper-lg .ant-input-search-icon{
  color: #777777;
}
.Right .ant-input-search-icon::before{
  border: 0;
}
.Centers{
  padding: 0 30px;
}
.Headers{
  display: flex;
  align-items: center;
  font-family: PingFangSC-Semibold;
  font-size: 14px;
  color: #454545;
  letter-spacing: 0;
  background: #E9EDF3;
  border: 1px solid #E9EDF3;
  height: 38px;
  line-height: 38px;
  padding: 0 18px;
}
.Headers span{
  text-align: left;
  font-weight: bold;
}
.Headers span:nth-child(1),
.BodysList .Item span:nth-child(1){
  width: 50%;
}
.Headers span:nth-child(2),
.BodysList .Item span:nth-child(2){
  width: 10%;
}
.Headers span:nth-child(3),
.BodysList .Item span:nth-child(3){
  width: 10%;
}
.Headers span:nth-child(4),
.BodysList .Item span:nth-child(4){
  width: 10%;
}
.Headers span:nth-child(5),
.BodysList .Item span:nth-child(5){
  width: 10%;
}
.BodysList{
  height: 780px;
  overflow-y: scroll;
  margin-bottom: 15px;
}
.BodysList .Item{
  display: flex;
  align-items: center;
  height: 68px;
  padding: 0 18px;
  text-align: left;
  border: 1px solid #E9EDF3;
  border-top: 0;
}
.BodysList .Item > span:nth-child(1){
  display: flex;
  align-items: center;
}
.BodysList .Item span:nth-child(1) > div{
  width: 42px;
  height: 42px;
  background: #CED9E2;
  border-radius: 50%;
  margin-right: 12px;
}
.BodysList::-webkit-scrollbar {
  width: 3px;     
  height: 1px;
}
.BodysList::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #D2D4D7;
  width: 410px;
}
.BodysList::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #fff;
}
.Top_Vi{
  padding: 35px 30px 13px;
}
.Top_Vit{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}
.Top_Vit div{
  display: flex;
  align-items: center;
}
.Top_Vit div:nth-child(1) span:nth-child(1){
  font-family: PingFangSC-Semibold;
  font-size: 22px;
  color: #454545;
  margin-right: 12px;
  font-weight: bold;
}
.Top_Vit div:nth-child(1) span:nth-child(2),
.Top_Vit div:nth-child(1) span:nth-child(3){
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #999999;
  margin-right: 12px;
}
.Top_Vit div:nth-child(2) .ant-btn:nth-child(1){
  border: 0;
  font-size: 14px;
  color: #454545;
}
.Top_Vit div:nth-child(2) .ant-btn:nth-child(2){
  font-size: 14px;
  border: 1px solid #E38D11;
  color: #454545;
  font-family: PingFangSC-Semibold;
  font-size: 16px;
  color: #E38D11;
  width: 80px;
  height: 30px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Top_Vib {
  display: flex;
  align-items: center;
}
.Top_Vib .ant-btn,
.footer_Vi  .ant-btn,
.aqzs789 .ant-btn{
  width: 103px;
  height: 40px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FFFFFF;
  border: 1px solid #D8D8D8;
  border-radius: 3px;
  font-family: PingFangSC-Semibold;
  font-size: 16px;
  color: #777777;
  margin-right: 16px;
}
.Top_Vib .ant-btn:focus{
  background: #71A6C5;
  color: #FFFFFF;
}
.Body_Vi{
  margin: 0 30px;
  padding: 17px 24px;
  background: #F8F9F9;
  border: 1px solid #E8E8E8;
  min-height: 700px;
  text-align: left;
  font-family: PingFangSC-Regular;
  font-size: 18px;
  color: #000000;
  line-height: 32px;
}
.Body_Vi p{
  margin-bottom: 0;
}
.footer_Vi{
  display: flex;
  justify-content: center;
  align-items: center;
  margin:  30px 0 ;
}
.footer_Vi .ant-input-group{
  width: auto;
}
.footer_Vi .ant-input-group.ant-input-group-compact > .ant-select:last-child > .ant-select-selector{
  width: 160px;
  height: 40px;
  background: #FFFFFF;
  border: 1px solid #3AA4CE;
  border-radius: 4px;
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #454545;
  line-height: 40px;
  margin-right: 23px;
}
.footer_Vi .ant-select-single.ant-select-show-arrow .ant-select-selection-item{
  line-height: 40px;
  text-align: left;
}
.footer_Vi .ant-btn{
  background: #3AA4CE;
  height: 40px;
  color: #FFFFFF;
}
.footer_Vi .ant-btn:nth-child(2){
  background: #FFFFFF;
  border: 1px solid #D8D8D8;
  color: #777777;
}
.footer_Vi .ant-select-arrow{
  right: 35px;
}
.ant-select-dropdown{
  width: 160px!important;
  min-width: 160px!important;
  height: 190px;
  overflow-y: scroll;
}
.ant-select-dropdown::-webkit-scrollbar {
  width: 3px;     
  height: 1px;
}
.ant-select-dropdown::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #D2D4D7;
  width: 410px;
}
.ant-select-dropdown::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #fff;
}
.gnlq1 .ant-modal-body{
  font-family: PingFangSC-Regular;
  font-size: 20px;
  color: #454545;
  text-align: center;
  line-height: 24px;
  padding: 90px 0;
}
.gnlq2 .ant-modal-header{
  padding: 14px 38px;
}
.gnlq2 .ant-modal-title{
  font-weight: bold;
}
.gnlq2 .ant-modal-body {
  padding: 24px 38px;
}
.gnlq2 .ant-modal-body textarea.ant-input{
  background: #FFFFFF;
  border: 1px solid #D8D8D8;
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #999999;
  width: 740px;
  height: 295px;
}
.gnlq2 .ant-input:focus{
  box-shadow:none
}
.aqzs789{
  margin: 23px 0 18px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.aqzs789 .ant-btn{
  width: 80px;
  height: 30px;
  font-size: 12px;
}
.aqzs789 .ant-btn:nth-child(1){
  margin: 0;
  border: 0;
}
.aqzs789 .ant-btn:nth-child(2){
  background: #3AA4CE;
  color: #FFFFFF;
}
.ant-btn:focus{
  border-color: transparent;
}

#header{
  background-color: #ffffff;
  padding: 0 30px;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 3px 8px rgba(3,21,34,0.05);
  margin-bottom: 1px;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 999;
}
.htopL{
  display: flex;
  align-items: center;
  height: 100%;
}
.htopL .wenb{
  font-family: PingFangSC-Semibold;
  font-size: 20px;
  color: #487FAB;
  font-weight: bold;
  margin-left: 12px;
  height: 100%;
  display: flex;
  align-items: center;
}
.caid{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 102px;
  height: 100%;
  text-align: center;
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #454545;
  border-right: 1px solid #E7E7E7;
  cursor: pointer;
}
.caid span{
  margin-left: 8px;
}
.htopR{
  display: flex;
  align-items: center;
  height: 100%;
}
.htopR .info{
  width: 170px;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0 20px;
  cursor: pointer;
}
.htopR .info .portrait{
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: aqua;
}
#footer {
  height: 63px;
  line-height: 63px;
  background-color: #f1f4f7;
  padding: 0;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 999;
}
#footer .wenb{
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #454545;
}
.htopC{
  font-family: PingFangSC-Semibold;
  font-size: 16px;
  color: #096dd9;
  height: 100%;
  width: 650px;
}
.htopC .ant-menu{
  height: 100%;
  border: 0;
}
.htopC .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected{
  color: #096dd9;
  border-bottom: 2px solid  #096dd9;
  font-family: PingFangSC-Semibold;
}
.htopC .ant-menu-horizontal > .ant-menu-item-selected a,
.htopC .ant-menu-horizontal > .ant-menu-item a:hover{
  /* color: #3BA7D1; */
  color: #096dd9;
}
.htopC .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item{
  height: 100%;
  line-height: 80px;
  font-size: 16px;
}
.htopC .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover{
  color:  #096dd9;
  border-bottom: 2px solid #096dd9;
}
.people{
  width: 123px;
  padding: 10px 0;
}
.people .ant-btn{
  width: 123px;
  height: 34px;
  padding: 0;
  line-height: 30px;
  text-align: center;
  border: 0;
  font-size: 12px;
  color: #454545; 
  font-family: PingFangSC-Regular;
}
.people .ant-btn:focus,
.people .ant-btn:hover{
  border-color: transparent;
  background-color: #F1F4F7;
  color: #454545;
}

.ant-btn:focus{
  border-color: transparent;
}
.logoimg{
  width: 255px;
  height: 45px;
}
.logoimg img{
  /* width: 100%; */
  height: 100%;
  vertical-align: baseline;
}
.add141{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.add141 .ant-dropdown-menu{
  width: 123px;
  height: 196px;
  padding: 14px 0;
}
.add141 .ant-dropdown-menu .ant-dropdown-menu-item{
  height: 34px;
  width: 123px;
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #454545;
  letter-spacing: 0;
  text-align: center;
  border-top: 1px solid #E9EDF3;
}
.add141 .ant-dropdown-menu .ant-dropdown-menu-item:nth-child(1){
  border-top: 0;
}
.langue .ant-input-group.ant-input-group-compact > .ant-select:last-child > .ant-select-selector{
  width: 102px;
}
.help{
  margin-right: 20px;
}
.changefont span{
  cursor: pointer;
}
.loginname,.help,.changefont{
  float: left;
}
.needchang:hover{
  color: #096dd9;
  text-shadow: 0 0px 10px #096dd9;
}
@media screen and (max-width:1000px) and (min-width:500px){

  .htopC{

   width: 50px;

   }
  }
  .helpInfoMessage{
    width: 500px;
    font-family: "中粗";
    height: 300px;
    overflow-y:scroll;
  }
  .helpp{
    padding-top: 10px;
  }
  .ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
    border-right: white!important;
    border-radius: 10px!important;
  }
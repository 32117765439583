.Acon {
    padding-right: 30px;
    display: flex;
    position: relative;
    padding: 0 100px;
}
.Acon_R {
    width: 100%;
}
.Acon_R > span{
    font-family: PingFangSC-Semibold;
    font-size: 22px;
    color: #454545;
    margin: 26px 0 22px;
    font-weight: bold;
    display: block;
    text-align: left;
}
.Acon_R > span{
    color: #3AA4CE;
}
.Acon_R > div:nth-child(1) {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}
.Acon_R .Acon_Rco{
    background: #FFFFFF;
    border: 1px solid #E8E8E8;
    padding: 20px;
    /* min-height: 650px !important; */
    font-size: 18px;
    color: #454545;
    line-height: 32px;
    text-align: left;
}
/* .Acon_R .Acon_Rco{
    background: #F8F9F9;
 } */

.back{
    float: right;
    background: rgba(113, 166, 197,1);
    color: white;
    border: 1px solid rgba(113, 166, 197,1);
    margin-right: 10px;
    margin-bottom: 10px;
}
.goback{
    margin: 0;
    padding: 0;
    outline: none;
    border-radius: 0;
    background-color: transparent;
    line-height: inherit;
    width: 35px;
    border: none;
    cursor: pointer;
    margin-left: 5px;
}
.saveModal .ant-input:placeholder-shown {
    text-overflow: ellipsis;
    border-radius: 40px;
}
.App .ant-form{
  text-align: left;
}
.log-reg{
  /* display: none; */
  /* opacity: 0; */
}
.verificationCode .ant-input-group-addon{
  padding: 0;
  border:none
}

.login-box{
  margin-top: 20px;
  margin-bottom: 20px;
  padding-top: 50px;
  padding-bottom: 50px;
  min-height: calc(100vh - 104px - 166px);
}
.login-title{
  font-size: 36px;
  color: #333;
  text-align: center;
  display: block;
  margin-bottom: 50px;
}
.code{
  width: 1.5rem;
  border: 1px solid #d9d9d9;
  height: 32px;
  line-height: 32px;
  text-align: center;
  letter-spacing: 10px;
  border-radius: 2px;
}
@media screen and (max-width: 1250px) {
  .login-box{
    margin-left: 20px;
    margin-right: 20px;
  }
}
@media (max-width: 575px){
  .ant-form .ant-form-item {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .ant-form .ant-form-item .ant-form-item-control {
    max-width: unset;
    width: 60%;
    flex: unset;
  }
  .ant-form .ant-form-item .ant-form-item-label {
    max-width: unset;
    width: 25%;
    flex: unset;
    padding-right: 10px;
  }
  .ant-col-offset-8{
    margin-left: 25%;
  }
  .ant-form-item .ant-form-item-label{
    text-align: right;
  }
}
.bg-e7ecef .ant-select-selector{
  background: none !important;
  }
  .rement{
    width: 1200px;
    margin: auto;
  }
  .rement2{
    width: 100%;
    margin: auto;
  }
  .rement .p1{
    text-align: left;
    font-size: 14px;
    color: #686868;
    line-height: 25px;
    font-family: 'Arial Normal', 'Arial';
  }
  .rement .p2{
    text-align: left;
    font-size: 14px;
    color: #686868;
    line-height: 25px;
    font-family: 'Arial Normal', 'Arial';
    text-indent: 2em;
  }
  .rement2{
    width: 1200px;
    margin: auto;
    margin-top: 10px;
  }
  .rement2 .p1{
    text-align: left;
    font-size: 14px;
    color: #686868;
    line-height: 25px;
    font-family: 'Arial Normal', 'Arial';
  }
  .rement2 .p2{
    text-align: left;
    font-size: 14px;
    color: #686868;
    line-height: 25px;
    font-family: 'Arial Normal', 'Arial';
    text-indent: 2em;
  }
  .rement2 .leftnav{
    float: left;
    width: 23%;
    height: auto;
    min-height: 200px;
    padding: 20px;
    border: 1px solid #d9d9d9;
  }
  .rement2 .rightcont{
    float: right;
    width: 75%;
    padding: 20px;
    border: 1px solid #d9d9d9;
  }
  .rement3{
    width: 1100px;
    margin: auto;
  }
  textarea::-webkit-input-placeholder {
    /* 修改字体颜色 */
    color: #ccc;
}
.content:focus{
  border: none;outline:none;
  }
  .content{
    padding-top: 2px;
  }
  .p2{
    cursor: pointer;
  }
.content{
    padding: 20px;
}
.allclass {
    margin-left: 5px;
}
.Acon{
    display: flex;
    position: relative;
}
.Acon_R{
    width: 100%;
}
.Acon_R > div:nth-child(1){
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}
.Acon_R > div:nth-child(1) .ant-btn{
    border-radius: 4px;
    width: 90px;
    height: 30px;
    padding: 0;
    line-height: 30px;
    text-align: center;
    font-size: 12px;
    color: #777777;
    background: #FFFFFF;
    border: 1px solid #D8D8D8;
    border-radius: 4px;
  }
  .Acon_R > div:nth-child(1) .ant-btn:focus{
    background: #3AA4CE;
    color: #FFFFFF;
    border: 0;
  }
  .Acon_R > span{color: #3AA4CE;
  }
.popsty{
    font-family: 中粗;
}

.popoverMenu .popbtn {
    border: none;
    background: none;
    font-family: 中粗;
}
.popoverMenu .popbtn .popicon svg{
    width: 1.5em;
    height: 1.5em;
    /* color: #096dd9; */
}
.pop .ant-popover .ant-popover-placement-bottom {
    left: 150px!important;
    top: 227px;
    transform-origin: 50% -4px;
    font-family: 中粗;
}
.addlabel{
    font-family: 中粗;
}
.saveIcon svg{
    width: 2em;
    height: 2em;
    /* margin-top: 5px; */
    color: #096dd9;
}
.linkdiv{
    border: 1px solid #dedede;
    padding: 5px;
    margin-top: 10px;
    overflow-y: auto;
    width: 92%;
    height: 400px;
    border-radius: 6px;
    font-family: 中粗;
}
.leftClass{
    border: 1px solid #dedede;
    float: left;
    width: 100% ;
    line-height: 38px;
    font-size: 16px;
    border-radius: 6px;
    font-family: 中粗;
}
.rightClass{
    display: flex;
    align-items: center;
    /* float: right; */
    padding-left: 10px
}
.rightClass .modify{
    margin-right: 10px;
    cursor: pointer;
}
.rightClass .modify img{
    width: 30px;
    height: 30px;
}
.rightClass .modify svg{
    width: 2em;
    height: 2em;
    color: #096dd9;
}
.linkTitle{
    /* border: 1px solid #7d7d7d; */
    padding: 5px 10px;
    color: #096dd9;
    font-family: 中粗;
}
.radioId{
    margin-left: 5px;
}
.ant-radio-button-wrapper {
    border-left: 1px solid #dedede;
    border-radius: 4px;
}
.ant-radio-button-wrapper:first-child {
    border-left: 1px solid #dedede;
    border-radius: 4px;
}
.ant-radio-button-wrapper:last-child {
    border-left: 1px solid #dedede;
    border-radius: 4px;
}

.main {
    width: 100%;
    height: 80%;
    color: #000;
    font-family: 中粗;
}
.noText{
    line-height: 400px;
    text-align: center;
    font-size: x-large;
}
.ant-empty {
    font-size: 14px;
    line-height: 200px;
    text-align: center;
}
.radioSty{
    border-radius: 10px;
}
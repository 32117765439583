.Atop{
  height: 46px;
  background: #71A6C5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: PingFangSC-Semibold;
  font-size: 14px;
  color: #FFFFFF;
  padding: 0 30px;
}
.Acon{
  /* padding: 0 30px; */
  /* padding-right: 30px; */
  display: flex;
  /* padding-right: 92px; */
  position: relative;
}
.Atop .Atop_l,
.Atop .Atop_r{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Atop .Atop_l > span{
  margin-right: 30px;
}
.Atop .Atop_r .ant-btn{
  height: 30px;
  background: #71A6C5;
  border: 0;
  padding: 0 18px;
  line-height: 30px;
  text-align: center;
}
.Atop .Atop_r .ant-btn:nth-child(1){
  border-right:1px solid #FFFFFF;
}

.Acon_L,
.Acon_R{
  width: 100%;
}

.Atop_l .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover{
  color: #FFFFFF;
  background-color: rgba(52,91,113,.5);
}
.Atop_l .ant-radio-button-checked{
  border:0;
  border-radius: 0;
}
.Atop_l .ant-radio-button-wrapper{
  border: 0;
  background:#71A6C5;
  color: #FFFFFF;
}
.Atop_l .ant-radio-button-wrapper:first-child{
  border-radius: 0;
  border: 0;
}
.Atop_l .ant-radio-button-wrapper:last-child{
   border-radius: 0;
}
.Atop_l .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
  color: #FFFFFF;
  background-color: rgba(52,91,113,.5);
  border:0
}
.Acon_L > span,.Acon_R > div:nth-child(1){
  font-family: PingFangSC-Semibold;
  font-size: 22px;
  color: #454545;
  margin: 26px 0 22px;
  font-weight: bold;
  display: block;
  text-align: left;
  height: 34px;
}
.Acon_R > div:nth-child(1){
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}
.Acon_R > div:nth-child(1) .ant-btn{
  border-radius: 4px;
  width: 90px;
  height: 30px;
  padding: 0;
  line-height: 30px;
  text-align: center;
  font-size: 12px;
  color: #777777;
  background: #FFFFFF;
  border: 1px solid #D8D8D8;
  border-radius: 4px;
}
.Acon_R > div:nth-child(1) .ant-btn:focus{
  background: #3AA4CE;
  color: #FFFFFF;
  border: 0;
}
.Acon_R > span{color: #3AA4CE;
}

.Acon_R .Acon_Rco{
  background: #FFFFFF;
  border: 1px solid #E8E8E8;
  padding: 20px;
  /* min-height: 500px; */
  font-size: 18px;
  color: #454545;
  line-height: 32px;
  text-align: left;
}
.Acon_L .Acon_Lco{
  border-right: 0;
}
.Acon_R .Acon_Rco{
  background: #F8F9F9;
}
.adwn2244{
  width: 80px;
  padding: 7px 0;
}
.adwn2244 .ant-btn{
  width: 80px;
  height: 30px;
  padding: 0;
  line-height: 30px;
  text-align: center;
  border: 0;
  font-size: 12px;
  color: #454545; 
  font-family: PingFangSC-Regular;
}
.adwn2244 .ant-btn:focus{
  background-color: #F1F4F7;
}
.adwn2244  .ant-popover-inner-content{
  padding: 0;
}
.adwn2244  .ant-btn:focus {
  outline: none;
}
html {
  --antd-wave-shadow-color:none;
}
.gnl2 .ant-modal-body{
  padding: 0;
}
.gnl2 .ant-modal-body{
  padding: 90px 0;
}
.gnl2 .ant-modal-body span{
  font-family: PingFangSC-Regular;
  font-size: 20px;
  color: #454545;
  text-align: center;
  line-height: 24px;
  display: block;
}
.content{
  padding: 4px 20px;
}
.content .addlabel{
  position: absolute;
  top: 82px;
  right: 30px;
  width: 80px;
}
.content .addlabel .ant-btn{
  border-radius: 40px;
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #FFFFFF;
  letter-spacing: 0;
  text-align: center;
  margin-bottom: 8px;
  padding: 0 12px;
  height: 32px;
}
.content .addlabel .zenj{
  font-size: 12px;
  margin-bottom: 8px;
  background: #FFFFFF;
  border: 1px solid #D2D4D7;
  border-radius: 4px;
  color: #454545;
  width: 54px;
}
.qeerwaa .ant-popover-inner-content{
  padding: 16px;
}
.qeerwaa .ant-popover-title{
  font-family: PingFangSC-Semibold;
  font-size: 14px;
  color: #454545;
  font-weight: bold;
  border-bottom: 0;
}
.qeerwaa .ant-popover-inner-content > div > div:nth-child(2){
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #999999;
  margin-top: 6px;
}
.qeerwaa .ant-btn,
.awq23 .ant-btn{
  background: #FFFFFF;
  border: 1px solid #487FAB;
  border-radius: 4px;
  width: 80px;
  height: 30px;
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #487FAB;
  letter-spacing: 0;
  margin: 8px 0;
}
.qeerwaa .ant-btn:nth-child(1),
.awq23 .ant-btn:nth-child(1){
  color: #777777;
  border: 1px solid #FFFFFF;
}
.qeerwaa .ant-input{
  font-size: 14px;
  color: #999999;
  border-right: 0;
}
.qeerwaa .ant-input:focus,
.qeerwaa .ant-input:hover{
  border-color: #D7D9DC;
  box-shadow:none
}
.colorsect{
  width: 100%;
  height: 100%;
  cursor: pointer;
  border: 1px solid #D7D9DC;
}

.qazxc456 .ant-modal-content{
  background: #F2F7FC;
  width: 100%;
  min-height: 460px;
  height: auto;
}
.qazxc456 .ant-modal-body{
  padding: 0 24px 51px;
}
.qazxc456 .ant-modal-content .ant-tabs-nav-wrap .ant-tabs-tab{
  font-family: PingFangSC-Regular;
  font-size: 18px;
  color: #777777;
  width: 80px;
  display: flex;
  justify-content: center;
}
.qazxc456 .ant-modal-content .ant-tabs-nav-wrap .ant-tabs-ink-bar{
  background: #487FAB;
}
.qazxc456 .ant-modal-content .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
  font-family: PingFangSC-Semibold;
  font-size: 18px;
  color: #454545;
  text-align: center;
}
.qazxc456 .ant-modal-content .key1 > .top{
   display: flex;
   flex-wrap: wrap;
   margin-bottom: 179px;
}
.qazxc456 .ant-modal-content .key1 > .top > div{
  display: flex;
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #E9EDF3;
  border-top: 0;
  line-height: 26px;
  font-family: PingFangSC-Semibold;
  font-size: 14px;
  color: #454545;
}
.qazxc456 .ant-modal-content .key1 > .top > div:nth-child(1){
  font-weight: bold;
}
.qazxc456 .ant-modal-content .key1 > .top > div > span{
  width: 50%;
  padding: 0 10px;
}
.qazxc456 .ant-modal-content .key1 > .top > div > span:nth-child(2){
  display: flex;
}
.qazxc456 .ant-modal-content .key1 > .top > div > span:nth-child(2) span{
  width: 50%;
}
.qazxc456 .ant-modal-content .key1 > .top > div > span:nth-child(2) span:nth-child(1){
  border-right: 1px solid #E9EDF3;
}
.qazxc456 .ant-modal-content .key1 > .top > div > span:nth-child(2) span:nth-child(2){
  padding: 0 10px;
}
.qazxc456 .ant-modal-content .key1 > .top > div > span:nth-child(1){
  border-right: 1px solid #E9EDF3;
}
.qazxc456 .ant-modal-content .key1 > .top > div > div{
  width: 50%;
  display: flex;
  padding:  0 10px;
  color: #487FAB;
}
.qazxc456 .ant-modal-content .key1 > .top > div > div > span{
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #487FAB;
  width: 55px;
}


.key1 .footer .ant-btn{
  background: #3AA4CE;
  border-radius: 4px;
  width: 100%;
  height: 40px;
  font-family: PingFangSC-Semibold;
  font-size: 14px;
  color: #FFFFFF;
  padding: 0;
  line-height: 40px;
  text-align: center;
  margin-top: 20px;
}
.qazxc456 .ant-modal-content .key3 > .top{
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 179px;
}
.qazxc456 .ant-modal-content .key3 > .top > div{
 display: flex;
 width: 100%;
 background: #FFFFFF;
 border: 1px solid #E9EDF3;
 border-top: 0;
 line-height: 26px;
 font-family: PingFangSC-Semibold;
 font-size: 14px;
 color: #454545;
}
.qazxc456 .ant-modal-content .key3 > .top > div:nth-child(1){
 font-weight: bold;
}
.qazxc456 .ant-modal-content .key3 > .top > div > span{
 width: 50%;
 padding: 0 10px;
}
.qazxc456 .ant-modal-content .key3 > .top > div > span:nth-child(1){
 border-right: 1px solid #E9EDF3;
}
.qazxc456 .ant-modal-content .key3 > .top > div > div{
 width: 50%;
 display: flex;
 padding:  0 10px;
 color: #487FAB;
}
.qazxc456 .ant-modal-content .key3 > .top > div > div > span{
 font-family: PingFangSC-Regular;
 font-size: 12px;
 color: #487FAB;
 width: 55px;
}
.key3 .footer .ant-btn{
 background: #3AA4CE;
 border-radius: 4px;
 width: 100%;
 height: 40px;
 font-family: PingFangSC-Semibold;
 font-size: 14px;
 color: #FFFFFF;
 padding: 0;
 line-height: 40px;
 text-align: center;
 margin-top: 20px;
}
.key2 .footer .ant-btn{
  background: #3AA4CE;
  border-radius: 4px;
  width: 100%;
  height: 40px;
  font-family: PingFangSC-Semibold;
  font-size: 14px;
  color: #FFFFFF;
  padding: 0;
  line-height: 40px;
  text-align: center;
  margin-top: 45px;
 }


.qazxc456 .ant-modal-content .key2 > .top{
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  width: 360px;
  border: 1px solid #E9EDF3;
}
.qazxc456 .key2 > .top > div:nth-child(1){
 display: flex;
 width: 100%;
 background: #FFFFFF;
 border: 1px solid #E9EDF3;
 border-top: 0;
 line-height: 26px;
 font-family: PingFangSC-Semibold;
 font-size: 14px;
 color: #454545;
 font-weight: bold;
 padding-left: 9px;
}
.qazxc456 .key2 > .top > div:nth-child(1) span{
  width: 50%;
}
.qazxc456 .key2 > .top > div:nth-child(1) span:nth-child(2){
  display: flex;
}
.qazxc456 .key2 > .top > div:nth-child(1) span:nth-child(2) span{
  width: 49%;
  border-left: 1px solid #E9EDF3;
  padding-left: 7px;
}
.qazxc456 .key2 > .top .list{
  width: 100%;
  height: 260px;
  overflow-y: scroll;
}
.qazxc456 .key2 > .top .list > div {
  display: flex;
  width: 100%;
  height: 26px;
  font-size: 14px;
  line-height: 26px;
  padding-left: 9px;
  border-top: 1px solid #E9EDF3;
}
.qazxc456 .key2 > .top .list > div:nth-child(1){
  border-top: 0;
}
.qazxc456 .key2 > .top .list > div > span{
  width: 50%;
}
.qazxc456 .key2 > .top .list > div > span:nth-child(2){
  display: flex;
}
.qazxc456 .key2 > .top .list > div > span:nth-child(2) span{
  width: 50%;
  border-left: 1px solid #E9EDF3;
  padding-left: 9px;
}
.list::-webkit-scrollbar {
  width: 3px;     
  height: 1px;
}
.list::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #D2D4D7;
  width: 410px;
}
.list::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #fff;
}

.History .ant-modal-body{
  padding-top: 0;
}
.History .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
  font-size: 18px;
  color: #454545;
  font-weight: bold;
}
.History .ant-tabs-tab:hover{
  color: #454545;
}
.History .ant-tabs-tab{
  font-size: 18px;
  color: #777777;
}
.Hisitem > div:nth-child(1) > span{
  font-weight: bold;
}
.History .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar{
  background: #487FAB;
}
.Hisitem{
  padding-bottom: 13px;
  border-bottom: 1px solid #E7EFF7;
  margin-bottom: 13px;
  margin-right: 40px;
}
.History .Hislist{
  height: 455px;
  overflow-y: scroll;
}
.Hislist::-webkit-scrollbar {
  width: 3px;     
  height: 1px;
}
.Hislist::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #D2D4D7;
  width: 410px;
}
.Hislist::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #fff;
}

.History .ant-modal-body{
  padding: 24px;
}
.Hisitem > div{
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: #454545;
}
.Hisitem .ant-switch-checked{
  background: #3AA4CE;
}
.Hisitem > div > div{
  width: 85px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  color: #3AA4CE;
}
.Hisitem .ant-switch {
  min-width: 32px;
  height: 16px;
}
.Hisitem .ant-switch-checked .ant-switch-handle {
  left: calc(100% - 8px - 5px);
}
.Hisitem .ant-switch-handle{
  width: 12px;
  height: 12px;
}
.Hisitem > div:nth-child(2) {
  margin-top: 6px;
}
.Hisitem > div:nth-child(2) > span:nth-child(1){
  font-size: 12px;
  color: #454545;
}
.Hisitem > div:nth-child(2) > span:nth-child(2){
  font-size: 12px;
  color: #999999;
}
.banhis > div > div{
  justify-content: flex-end;
}
.biaoz{
  border: 0;
  background-color:  #D54444;
  font-size: 18px;
  padding: 0 10px;
}
.biaoz:focus,.biaoz:hover{
  border: 0;
  background-color:  #D54444;
  color: #000;
}
.biaoge{
  width: 494px;
  border: 1px solid #CED9E2;
} 
.biaoge > .ant-checkbox-wrapper{
  width: 100%;
  background: #F1F4F7;
  height: 31px;
  line-height: 31px;
  padding-left: 13px;
  border-bottom: 1px solid #CED9E2;
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #454545;
  letter-spacing: 0;
  line-height: 28px;
}
 .biaoge .ant-checkbox-group{
  background-color: #FFFFFF;
  padding-left: 13px;
}
 .biaoge .ant-checkbox-group-item{
  width: 100%;
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #454545;
  letter-spacing: 0;
  line-height: 28px;
  padding-top: 3px;
  margin-right: 0;
}
.lai123 .main .biaoge .ant-checkbox-group{
  height: 191px;
  overflow-y: scroll;
}
.biaoge .ant-checkbox-group::-webkit-scrollbar {
  width: 3px;     
  height: 1px;
}
.biaoge .ant-checkbox-group::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #D2D4D7;
  width: 410px;
}
.biaoge .ant-checkbox-group::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #fff;
}
.biaoge .ant-checkbox-checked .ant-checkbox-inner{
  background: #3AA4CE;
  border: 1px solid #3AA4CE;
  border-radius: 3px;
}
.ant-checkbox-checked .ant-checkbox-inner{
  background: #3AA4CE;
  border: 1px solid #3AA4CE;
  border-radius: 3px;
}
.ant-checkbox-indeterminate .ant-checkbox-inner::after{
  background: #3AA4CE;
}


.qwew789r .ant-tabs-nav-wrap .ant-tabs-tab{
  font-family: PingFangSC-Regular;
  font-size: 18px;
  color: #777777;
  display: flex;
  justify-content: center;
}
.qwew789r .ant-tabs-nav-wrap .ant-tabs-ink-bar{
  background: #487FAB;
}
.qwew789r .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
  font-family: PingFangSC-Semibold;
  font-size: 18px;
  color: #454545;
  text-align: center;
}
.qwew789r .ant-modal-body{
  padding: 24px;
  padding-top: 0;
  height: 543px;
}
.qwew789r .top{
  padding-top: 30px;
  padding-bottom: 23px;
  padding-left: 162px;
}
.qwew789r .top .ant-input-group-wrapper{
  width: 400px;
  height: 38px;
}
.qwew789r .Search .ant-input{
  font-size: 14px;
  width: 400px;
  height: 38px;
}
.Search{
  width: 400px;
  height: 38px;
}
.Search .ant-btn{
  width: 74px;
  height: 38px;
  padding: 0;
  line-height: 38px;
  background: #3AA4CE;
  border-radius: 4px;
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #FFFFFF;
  border: 0;
}
.qwew789r .body .ant-tabs-nav-wrap .ant-tabs-tab{
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #454545;
  line-height: 20px;
}
.qwew789r .ant-modal-title{
  font-size: 18px;
  color: #777777;
}
.qwew789r .body .ant-tabs-nav-wrap .ant-tabs-ink-bar{
  background: #487FAB;
  display: none;
}
.qwew789r .body .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
  font-family: PingFangSC-Semibold;
  font-size: 14px;
  color: #487FAB;
}
.iu789 .ant-table-thead > tr > th {
  height: 30px;
  padding: 0 10px;
  background: #F1F4F7;
}
.iu789 .ant-table-thead {
  border: 0;
  border-bottom: 1px solid #E5EBF0;
}
.iu789 .ant-table-tbody{
  height: 270px;
  overflow-y: scroll;
}
.iu789 .ant-table-tbody > tr > td{
  height: 30px;
  padding: 0 10px;
}
.iu789 .ant-table table{
  border: 1px solid #E5EBF0;
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #454545;
  border-bottom: 0;
}
.iu789 .desc > span{
  display: block;
}
.iu789 .desc{
  height: 131px;
  overflow-y: scroll;
  display: block;
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #454545;
  line-height: 24px;
}
.iu789 .desc::-webkit-scrollbar {
  width: 3px;     
  height: 1px;
}
.iu789 .desc::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #D2D4D7;
  width: 410px;
}
.iu789 .desc::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #fff;
}
.qwew789r .body .ant-tabs-top .ant-tabs-nav{
  margin-bottom: 0;
}
.qwew789r .anniu{
  margin-top: 19px;
  margin-bottom: 30px;
  height: 30px;
  text-align: right;
}
.qwew789r .anniu .ant-btn{
  width: 110px;
  padding: 0;
  line-height: 30px;
  border-radius: 4px;
  text-align: centers;
}
.qwew789r .anniu .tih{
  background: #FFFFFF;
  border: 1px solid #D7D9DC;
  color: #454545;
  margin: 0 20px 0 16px;
}
.qwew789r .anniu .bc{
  background: #3AA4CE;
  color: #FFFFFF;
}
.qwew789r .biaoge {
  width: 100%;
  height: 299px;
}
.qwew789r .biaoge > .ant-checkbox-wrapper,
.qwew789r .biaoge .ant-checkbox-group-item{
  height: 40px;
  line-height:40px;
  border-bottom: 1px solid #E5EBF0;
  padding-left: 13px;
  padding-top: 0;
}
.qwew789r .biaoge .ant-checkbox-group-item:last-child{
  border-bottom: 0;
}
.qwew789r .biaoge .ant-checkbox-group{
  height: 254px;
  overflow-y: scroll;
  padding-left: 0;
}
.qwew789r .biaoge .ant-checkbox:focus .ant-checkbox-group-item{
  background: #F1F4F7;
}
.qwew789r .title span:nth-child(1){
  font-family: PingFangSC-Semibold;
  font-size: 14px;
  color: #D54444;
  line-height: 20px;
}
.qwew789r .title{
  margin-bottom: 6px;
}
.qwew789r .title span:nth-child(2){
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #3F3F3F;
  line-height: 20px;
}
.awq23 .ant-popover-inner{
  width: 568px;
  padding: 14px 19px;
}
.awq23 .ant-popover-title{
  padding: 0;
  border: 0;
}
.awq23 .ant-radio-wrapper{
  width: 100%;
  display: flex;
  align-items: center;
  height: 30px;
}
.awq23 .ant-radio-group{
  width: 100%;
}
.awq23 .ant-btn{
  margin-top: 13px;
}
.ant-btn:focus{
  border-color: transparent;
}
.lg{
  height: 300px;
  width: 70px;
  margin: auto;
}
.lg>div{
  float: left;
}
.lg .lg_l{
  /* line-height: 300px; */
  /* writing-mode: tb-rl; */
  width: 30px;
  margin: 100px auto;
}
.tpn{
  /* background: #d7f8ff; */
  color: Black;
  background-color: #c3eca3;
  /* cursor:pointer; */
  /* border: 1px solid #d7f8ff; */
  /* font-size: 20px; */
}
.liter{
  /* background: #fef1d2; */
  /* color:  #e199b7; */
  color: Black;
  background-color: #e199b7;
  /* cursor:pointer; */
  /* border: 1px solid #D54444; */
  /* font-size: 20px; */
}
.peop{
  /* background: #fef1d2; */
  color: Black;
  background-color: #f7e298;
  /* cursor:pointer; */
  /* border: 1px solid #D54444; */
  /* font-size: 20px; */
}
.offi{
  /* background: #cec5fa; */
  color: Black!important;
  background-color: #caa4f0;
  /* cursor:pointer; */
  /* border: 1px solid #cec5fa; */
  /* font-size: 20px; */
}
.date{
  /* background: #cec5fa; */
  color: Black;
  background-color: #8ecffd;
  /* cursor:pointer; */
  /* border: 1px solid #cec5fa; */
  /* font-size: 20px; */
}
.ant-radio-button-checked {
  border: none !important;
}
.uioio12 .ant-modal-content{
  background: none;
}
.Atop{
    height: 46px;
    background: #71A6C5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: PingFangSC-Semibold;
    font-size: 14px;
    color: #FFFFFF;
    padding: 0 30px;
  }
  .Atop .Atop_l,
  .Atop .Atop_r{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .Atop .Atop_l .ant-btn{
      width: 84px;
      height: 30px;
      background: #71A6C5;
      border: 0;
      padding: 0;
      line-height: 30px;
      text-align: center;
  }
  .Atop .Atop_l .ant-btn span,
  .Atop .Atop_r .ant-btn span{
    color: #FFFFFF;
  }
  .Atop .Atop_l .ant-btn:focus{
    background: rgba(52,91,113,.5);
  }
  .Atop .Atop_l > span{
    margin-right: 30px;
  }
  .Atop .Atop_r .ant-btn{
    height: 30px;
    background: #71A6C5;
    border: 0;
    padding: 0 18px;
    line-height: 30px;
    text-align: center;
  }
  .Atop .Atop_r .ant-btn:nth-child(1){
    border-right:1px solid #FFFFFF;
  }
  .Acon{
    display: flex;
    position: relative;
    padding: 0 100px;
  }
  .Acon_L{
    width: 100%;
  }
  .Acon_L > span,.Acon_R > span{
    font-family: PingFangSC-Semibold;
    font-size: 22px;
    color: #454545;
    margin: 26px 0 22px;
    font-weight: bold;
    display: block;
    text-align: left;
  }
  .Acon_R > span{color: #3AA4CE;
  }
  .Acon_L .Acon_Lco,
  .Acon_R .Acon_Rco{
    background: #FFFFFF;
    border: 1px solid #E8E8E8;
    padding: 20px;
    /* min-height: 650px !important; */
    font-size: 18px;
    color: #454545;
    line-height: 32px;
    text-align: left;
  }
  .Acon_L .Acon_Lco{
    border-right: 0;
  }
  .Acon_R .Acon_Rco{
    background: #F8F9F9;
  }
  .adwn2244{
    width: 80px;
    padding: 7px 0;
  }
  .adwn2244 .ant-btn{
    width: 80px;
    height: 30px;
    padding: 0;
    line-height: 30px;
    text-align: center;
    border: 0;
    font-size: 12px;
    color: #454545; 
    font-family: PingFangSC-Regular;
  }
  .adwn2244 .ant-btn:focus{
    background-color: #F1F4F7;
  }
  .adwn2244 .ant-popover-inner-content{
    padding: 0;
  }
  .adwn2244  .ant-btn:focus {
    outline: none;
  }
  .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover{
    color: #FFFFFF;
    background-color: rgba(52,91,113,.5);
  }
  .adwn2244 .ant-radio-button-wrapper:hover{
    color: #FFFFFF;
  }
  .Atop .ant-radio-button-checked{
    border:0;
    border-radius: 0;
  }
  .Atop .ant-radio-button-wrapper{
    border: 0;
    background:#71A6C5;
    color: #FFFFFF;
  }
  .Atop .ant-radio-button-wrapper:first-child{
    border-radius: 0;
    border: 0;
  }
  .Atop .ant-radio-button-wrapper:last-child{
     border-radius: 0;
  }
  .Atop .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
    color: #FFFFFF;
    background-color: rgba(52,91,113,.5);
    border:0
  }
  .Acon_L .Acon_Lco .right{
    display: flex;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
    padding-top: 125px;
    text-align: center;
  }
  .Acon_L>.right_A{
    
    border: none;
    padding: 0;
  }
  .Acon_Lco .right .ant-btn{
    width: 112px;
    height: 40px;
    border-radius: 4px;
    font-size: 14px;
    background: #3AA4CE;
    border-radius: 4px;
    font-family: PingFangSC-Semibold;
    text-align: center;
    color: #ffffff;
  }
  .Acon_Lco .ziwen{
    font-family: PingFangSC-Regular;
    font-size: 20px;
    color: #999999;
    margin: 42px 0 30px;
  }
  .Acon_Lco .right > span,
  .Acon_Lco .right > div{
    width: 100%;
  }
  .uioio11 .ant-modal-title{
    font-family: PingFangSC-Regular;
    font-size: 20px;
    color: #454545;
  }
  .uioio11 .ant-modal-header{
    border-bottom: 0;
  }
  .uioio11 .ant-modal-body{
    padding-bottom: 124px;
  }
  .uioio11 .ant-progress-bg{
    background-color: #3AA4CE;
  }
  .uioio12 .ant-modal-body{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .uioio12 .ant-modal-body > div,
  .uioio12 .ant-modal-body > span{
    width: 100%;
    text-align: center;
  }
  .uioio12 .ant-modal-body > span{
    margin: 15px 0 35px;
    display: block;
    font-size: 14px;
    color: #999999;
    text-align: center;
    line-height: 24px;
  }
  .uioio12 .ant-modal-body .ant-btn{
    width: 80px;
    height: 30px;
    padding: 0;
    border-radius: 4px;
    font-size: 12px;
    color: #454545;
    background: #FFFFFF;
    border: 1px solid #D7D9DC;
    border-radius: 4px;
    font-family: PingFangSC-Semibold;
    text-align: center;
  }
  .uioio12 .ant-modal-body {
    padding: 40px 0 34px;
  }
  .ant-btn:focus{
    border-color: transparent;
  }
  .woc123{
    width: 80px;
  }
  .woc123 .ant-dropdown-menu-item{
    font-family: PingFangSC-Regular;
    font-size: 12px;
    color: #454545;
    letter-spacing: 0;
    text-align: center;
  }
  .content .address_Color1{
    background-color: #487FAB;
    color: #FFFFFF;
    border: 1px solid #487FAB;
  }
  .content .address_Color2{
    border: 1px solid #487FAB;
    color: #487FAB;
    background-color: #FFFFFF;
  }
  .content .address_Color3{
    background-color: #cccccc;
    color: #487FAB;
    border: 1px solid #cccccc;
  }
  .r_btn{
    float: right;
    /* background: rgba(113, 166, 197,1); */
    color: white;
    /* border: 1px solid rgba(113, 166, 197,1); */
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .resave{
    float: right;
    background: rgba(113, 166, 197,1);
    color: white;
    border: 1px solid rgba(113, 166, 197,1);
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .tipslformat{
    display:inline-block;
    width:70px;
    height:30px;
    border-Right:1px solid #E8E8E8;
  }
  .tipsrformat{
    display:inline-block  !important;
    /* width:92px; */
    /* height:32px; */
    text-align: center;
  cursor: pointer;
  }
  .Acon_Rco .ant-select-selector{
  height: 100%;
  background: none !important;
  border: none !important;
  text-align: center;
  }
  .ziwen:focus{
    border: none;
    outline:none;
    }
    #content33fdd3{
      /* min-height: 790px !important; */
      padding-top: 80px;
      padding-bottom: 63px;
      /* font-family: "中粗"; */
    }
    .content span{
      /* line-height: 1.5715; */
    }
    .content>p{
      margin-bottom: 0;
    }
    #doc .wyd_btn{
      margin-Right:0;
      background:#096dd9 ;
      font-Size:14px;
      box-sizing: content-box;
      color:white ;
      margin-bottom: 0;
      border-radius: 3px;
      border: 1px solid #096dd9 ;
      margin-left: 5px;
      /* font-weight: 600; */
      font-family: "中粗" !important;
    }
    .increase>span{
      font-size: 16px;
    }
    .decline>span{
      font-size: 12px;
    }
    .wyd_btn:hover{
      opacity: 0.8 ;
    }
    .mycolor:hover{
      color: #096dd9 !important;
      border:1px solid #096dd9 !important;
    }
    .left_btn:hover{
      opacity: 0.8 ;
    }
    ._closethis:hover{
      color: #096dd9;
      cursor: pointer;
    }
    .ant-modal-content{
      /* background: none; */
      box-shadow: none;
    }
    
.contop{
  height: 78px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #E7E7E7;
  justify-content: center;
}
.contop .ant-steps{
  width: 700px;
}
.contop .ant-steps-item-container{
  display: flex;
  align-items: center;
}
.contop .ant-steps-item-process .ant-steps-item-icon,
.contop .ant-steps-item-finish .ant-steps-item-icon{
  background: #3AA4CE;
  border: 0;
  width: 46px;
  height: 46px;
  line-height: 46px;
  font-family: PingFangSC-Semibold;
  font-size: 24px;
  color: #FFFFFF;
}
.contop .ant-steps-item-wait .ant-steps-item-icon{
  background: #FFFFFF;
  width: 46px;
  height: 46px;
  border: 2px solid #D2D4D7;  
  text-align: center;
}
.contop .ant-steps-item-wait .ant-steps-item-icon span{
  font-family: PingFangSC-Semibold;
  font-size: 24px;
  color: #999999;
  line-height: 46px;
}
.contop .ant-steps-item-process .ant-steps-item-title,
.contop .ant-steps-item-finish .ant-steps-item-title{
  font-family: PingFangSC-Semibold;
  font-size: 20px;
  color: #3AA4CE;
}
.contop .ant-steps-item-wait .ant-steps-item-title{
  font-family: PingFangSC-Semibold;
  font-size: 20px;
  color: #999999;
}
.contop .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title,
.contop .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title{
  color: #3AA4CE;
}
.contop .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after{
  background: #D2D4D7;
}
.contop .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after{
  background-color: #3AA4CE;
}

.contop .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon{
  color:#FFFFFF;
}
.ant-btn:focus{
  border-color: transparent;
}
body{
      font-family: SimSun;
}
h1{
  font-weight: bold;
}

.bord-b{
  border-bottom: 1px solid #d7e1e7;
  width: 100%;
}
.title-Box{
  padding-top: 30px;
  padding-bottom: 10px;
}
.title{
  font-size: 26px;
  margin-left: 10px;
}
.info-Box{
  padding: 50px 0;
}
.yjry-img{
  width: 30%;
}
.name{
  font-size: 24px;
  color: #333;
  margin-right: 15px;
}
.zhicheng{
  font-size: 16px;
  color: #333;
  font-style: italic;
}
.paragraph{
  text-indent: 2em;
  font-size: 16px;
  margin-bottom: 10px;
  color:#6d6d70;
}
.member .ant-carousel .slick-dots li button{
  background: #000;
}
.member .ant-carousel .slick-dots li.slick-active button{
  background: #000;
}
.more{
  color: #999;
}
.person-info{
  padding-left: 30px;
}
.results-info{
  padding-right: 30px;
}
.tools{
  padding: 20px;
  border-radius: 4px;
  box-sizing: border-box;
  white-space: normal;
  word-wrap: break-word;
  word-break: break-all;
  overflow: hidden;
  display: inline-block;
}
.tools:first-child{
  margin-left: 0;
}
.tools-title{
  text-align: left;
  margin-bottom: 10px;
  display: block;
  font-size: 16px;
}
.search-box{
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 20px;
  min-height: calc(100vh - 104px - 166px);
}
.n-position{
  text-align: left;
}
.comeback{
  padding: 0 20px;
  line-height: 50px;
}
.person-name{
  line-height: 50px;
  padding: 0 20px;
  font-size: 16px;
}
.person-jianjie{
  padding: 20px;
}
.person-img{
  width: 2.5rem;
  margin-right: 30px;
}
.person-label{
  width: 100px;
  text-align: left;
  display: inline-block;
}
.person-jieshao{
  flex: 1;
  display: inline-block;
  text-align: left;
}
.person-title{
  text-align: left;
  line-height: 50px;
  padding: 0 20px;
}
.pt-title{
  border-left: 5px solid #4e81ae;
  padding-left: 10px;
  font-size: 18px;
}
.person-box{
  text-align: left;
}
.person-box div:last-child{
  border: 0;
  margin: 0;
}
.person-left-box{
  padding-right: 10px;
}
.person-right-box{
  padding-left: 10px;
}
.wenxian-title{
  font-size: 16px;
  font-weight: bold;
  display: block;
  margin-bottom: 10px;
}
.tbxs-title span{
  font-size: 18px;
  position: relative;
}
.tbxs-title span::before{
  content: '';
  width: 6px;
  height: 20px;
  background: #4e81ae;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.tbxs-title .ant-divider-horizontal.ant-divider-with-text-left::before{
  width: 0;
}
.content0-wrapper .content0 {
  height: auto;
}
@media screen and (max-width: 1250px) {
  .header{
    padding-left: 20px;
    padding-right: 20px;
  }
  .info-Box{
    padding: 50px 20px;
  }
  .title-Box{
    padding-left: 20px;
    padding-right: 20px;
  }
  .search-box{
    margin: 20px;
  }

  
  /* #Banner6_0 .ant-col-18{
    display: none;
  } */
  
  
}
@media screen and (max-width: 768px){
  .person-left-box{
    padding-right: 0;
    margin-bottom: 20px;
  }
  .person-right-box{
    padding-left: 0;
  }
  /* #Banner6_0{
    margin-right: 50px;
  } */
}
.ant-tabs-nav-wrap{
  float: left;
}

.table{
  border: 1px solid #ddd;
  border-radius: 4px;
}
.table-td{
  display: flex;
  flex-direction: row;
  align-items: stretch;
  border-bottom: 1px solid #ddd;
}
.table-td:last-child{
  border-bottom: 0;
}
.table-td-label{
  width: 100px;
  background-color: #eee;
  border-right: 1px solid #ddd;
  line-height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.table-td:first-child .table-td-label{
  border-top-left-radius: 4px;
}
.table-td:last-child .table-td-label{
  border-bottom-left-radius: 4px;
}
.table-td-content{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 10px;
  flex: 1;
  flex-wrap: wrap;
}
.table-td-item{
  display: block;
  margin-right: 30px;
  line-height: 34px;
}

.text{
  overflow : hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
}
.text1{
  overflow : hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
}
.text3{
  overflow : hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
}
.text4{
  overflow : hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
}
.text6{
  overflow : hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
}
.text8{
  overflow : hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 8;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
}
.text10{
  overflow : hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 10;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
}


.select-max-height .ant-select-selector{
   max-height: 32px;
   overflow-y: scroll;
  max-width: 199px;
}

.select-max-width .ant-select-selector{
   max-height: 32px;
   overflow-y: scroll;
  max-width: 263px;
}


.mb-20{
  margin-bottom: 40px;
}

.jg-help:hover{
  color: red;
}
.article-title{
  font-size: 24px;
  text-align: left;
  display: block;
  margin: 30px 0 20px;
}
.author{
  display: inline-block;
  margin-right: 20px;
}
.laiyuan{
  display: inline-block;
  margin-right: 20px;
}
.time{
  display: inline-block;
  margin-right: 20px;
}
.paragraph{
  text-indent: 2em;
  margin-bottom: 10px;
}
.flexclo span{
  display: block;
  
}

/* .ant-list-pagination {
    text-align: center;
} */

.detail p span span {
  font-size: 14px;
  line-height: 28px;
}
.ant-layout .footer {
  background: #243440;
}

.footer .mb-10 a span{
  font-weight: 600;
}

.footer .mb-10:nth-child(3),
.footer .flexRow{
  display: inline-block;
}
.footer .mb-10:nth-child(1) span .ant-divider{
  margin: 0 15px;
}
.footer .flexRow img{
  margin-left: 0.2rem;
  margin-right: 5px;
  vertical-align: -22%;
}


.ShaShiDi{
  width:500px;
  height:400px;
  display:flex;
  align-items:center;
  justify-content:center;
        /*为了效果明显，可以将如下边框打开，看一下效果*/
        /* border:1px solid black; */
  }
  
  .ShaShiDi img{
    width:100%;
    height:auto;
  }


  .tl .demo-infinite-container{
    width: 115px;
    background-color: #fff;
    padding: 0;
    height: 500px;
    margin-top: 5px;
  }
  .tl .demo-infinite-container::-webkit-scrollbar {
  width: 3px;     
  height: 1px;
  }
  .tl .demo-infinite-container::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #D2D4D7;
  width: 410px;
  }

  .tl .demo-infinite-container::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #fff;
  }
  
  .tl .demo-infinite-container .ant-row{
    padding: 7px 0 7px 15px;
  }
  .tl .demo-infinite-container .ant-row .ant-checkbox{
    margin-top: 3px;
  }
  .tl .demo-infinite-container .ant-checkbox-group{
    width: 100%;
  }
  .tl .demo-infinite-container .ant-row:hover{
    background-color: #F1F4F7;
  }
  .tl .demo-infinite-container .ant-row .ant-checkbox-wrapper{
    display: flex;
    align-items: center;
  }
  .tl .demo-infinite-container .ant-row .ant-checkbox-wrapper span:nth-child(2){
    padding-left: 15px;
  }
  .sdads3333{
    display: flex;
    justify-content: center;
    /* align-items: center; */
  }
  .sdads3333 > span{
    font-size: 40px;
    padding: 0 10px;
    color: #AA8245;
  }
  .sdads3333 .ant-radio-group{
    display: flex;
    /* align-items: center; */
  }
  .sdads3333 .ant-radio-group > span{
    width: 50px;
    height: 2px;
    background-color: #EAE3D4;
    margin-top: 19px;
  }
  .sdads3333 .ant-radio-group .ant-radio-wrapper{
    display: flex;
    width: 16px;
    padding-top: 12px;
    justify-content: center;
    flex-wrap: wrap;
    margin-right: 0;
    text-align: center;
  }
  .sdads3333 .ant-radio-group .ant-radio-wrapper > span:nth-child(2){
    width: 16px;
    white-space: pre-wrap;
    margin-top: 5px;
    padding: 0;
    color: #AA8245;
  }
  .sdads3333 .ant-radio-group .ant-radio-wrapper .ant-radio-inner::after{
    background-color: #AA8245;
  }
  .sdads3333 .ant-radio-checked .ant-radio-inner{
    background-color: #AA8245;
    border: 3px solid #AA8245;
  }

  .sdads3333 .ant-radio-input:focus + .ant-radio-inner{
    border: 0;
  }
  .sdads3333 .ant-radio:hover .ant-radio-inner{
    border-color: #d9d9d9;
  }
  .sdad65652 > input{
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #999999;
  }
  .sdad65652 > .ant-btn{
    width: 52px;
    background-color: #4e81ae;
  }
  .bg-e7ecef .descs{
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #999999;
    line-height: 24px;
    width: 350px;
    text-align: left;
    /* height: 425px; */
    margin-top: 10px;
  }
  .bg-e7ecef .descs p{
    margin-bottom: 0;
  }
  .sdad65652 .descs p{
    margin-bottom: 18px;
  }
  .bg-e7ecef .titles{
    display: flex;
    font-family: PingFangSC-Semibold;
    font-size: 20px;
    color: #454545;
    margin-bottom: 10px;
  }
  .vvsda22 .ant-space-align-center{
    align-items: flex-start;
  }
  .box1s .ant-space-item{
    padding: 5px 10px;
    border: 1px solid #ccc;
    color: #fff;
  }
  .box1s .sdadsad222{
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #454545;
    letter-spacing: 0.1px;
  }
  .daxczc223e2 .ant-btn,
  .dad3ds .ant-btn,
  .vvsda22 .ant-btn,
  .das3fd .ant-btn{
    width: 52px;
    background-color: #4e81ae;
  }
  .das3fd > input,
  .daxczc223e2 > input
  .dad3ds > input,
  .vvsda22 > input {
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #999999;
}
.box2s h2,.dad3ds h2,.sad2333 h2 , .daxczc223e2 h2,.das3fd h2{
  font-family: PingFangSC-Semibold;
  font-size: 14px;
  color: #454545;
  letter-spacing: 0.1px;
  font-weight: bold;
  margin-bottom: 18px;
}
.dad3ds{
  display: flex;
  margin-left: 18px;
}
/* .dad3ds .ant-space-align-center{
  align-items: flex-start;
} */
.ads232 .ant-space-item{
  padding: 5px 10px;
  border: 1px solid #ccc;
  color: #fff;
  width: 110px;
  height: 40px;
  line-height: 31px;
  text-align: center;
}
.sdas23ds a{
  display: inline-block;
  padding: 5px 10px;
  border: 1px solid #ccc;
  width: 110px;
  height: 40px;
  line-height: 31px;
  text-align: center;
  margin-bottom: 30px;
}
.sdas23ds{
  margin-right: 50px;
}
.ads232{
  margin-left: 50px;
}
.daxczc223e2{
  margin-left: 18px;
  margin-right: 30px;
}
.das3fd{
  margin-left: 18px;
  margin-top: 40px;
}
.das3fd h4{
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #999999;
  letter-spacing: 0.1px;
  margin: 12px 0;
}
.sda22esacc h5{
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #999999;
}
.sda22esacc .titles{
  margin-bottom: 0;
}
.sda22esacc{
  margin-bottom: 20px;
}
.jsi2k2 .ant-modal-content{
  height: 100%;
}
.jsi2k2 .ant-modal-title{
  font-family: PingFangSC-Semibold;
  font-size: 18px;
  color: #454545;
  font-weight: bold;
}
.jsi2k2 .dagsd2 {
  width: 775px;
  height: 80px;
  background-color: #F1F4F8;
  padding: 5px;
  font-family: PingFangSC-Semibold;
  font-size: 14px;
  color: #454545;
  line-height: 38px;
  font-weight: bold;
  margin-bottom: 10px;
}
.jsi2k2 .dagsd2 span{
  margin: 0 8px;
}
.jsi2k2 .ant-btn-dashed{
  background: #FFFFFF;
  border: 1px solid #AA8245;
  border-radius: 4px;
  color: #AA8245;
  width: 76px;
  height: 26px;
  text-align: center;
  line-height: 26px;
  padding: 0;
}
.jsi2k2 .jg span,.jsi2k2 .zw span{
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #AA8245;
  line-height: 28px;
}
.jsi2k2 .jg{
  color: #454545;
  line-height: 28px;
  margin-bottom: 28px;
}
.jsi2k2 .zw{
  margin-bottom: 28px;
}
.jsi2k2 .zw span{
  display: block;
}
.jsi2k2 .zw span:nth-child(2){
  color: #454545;
  text-indent:2em;
  line-height: 28px;
}
.jsi2k2 .tn{
  margin-bottom: 28px;
}
.jsi2k2 .tn > div:nth-child(1){
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #AA8245;
  line-height: 28px;
}
.jsi2k2 .tn > div:nth-child(2){
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #454545;
  line-height: 28px;
  text-indent:2em;
}
.jsi2k2 .tn > div:nth-child(2) > div{
  line-height: 28px;
}
.jsi2k2 .Mindesc{
  overflow-y: scroll;
  height: 500px;
}
.Mindesc::-webkit-scrollbar {
  width: 3px;     
  height: 1px;
}
.Mindesc::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #D2D4D7;
  width: 410px;
}

.Mindesc::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #fff;
}
.jsi2k2 .dian{
  display: inline-block;
  width: 5px;
  height: 5px;
  background-color: #454545;
  border-radius: 50%;
  margin-right: 25px;
  margin-bottom: 3px;
}
.rightbtnbox{
  width: 80px;
  display: flex;
  flex-wrap: wrap;
  position: fixed;
  top: 40%;
  right: 0;
  z-index: 99;
}
.rightbtnbox .ant-btn{
  width: 70px;
  height: 70px;
  background-color: #F1F4F7;
  padding: 0 10px;
}
.rightbtnbox .ant-btn span{
  width: 40px;
  white-space: pre-wrap;
  line-height: 20px;
}
.qiebox{
  margin-bottom: 15px;
}
.qiebox .ant-btn{
  width: 120px;
  height: 40px;
  background: #FFFFFF;
  border-radius: 4px;
  padding: 0;
  line-height: 40px;
  text-align: center;
}
